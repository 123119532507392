import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { Fragment, useState } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import { useMutation } from "@apollo/client";
import { JOB_CREATE_ONE, JOB_MANY } from "../../schema/job";
import { VACANCY_CREATE_ONE, VACANCY_MANY } from "../../schema/vacancy";

import { Confirm } from "components/ui/overlays/Confirm";
import { Link, PermissionRequired } from "components/ui/permissions";

import { currencyFormater } from "../../config/i18n";
import { Competence, Employee, Job } from "../../types";
import MicroChart from "../charts/MicroChart";
import Avatar from "../employees/Avatar";

interface Props {
  job: Job;
}

const Item = ({ job }: Props) => {
  const history = useHistory();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const openDialog = () => setIsDialogOpen(true);
  const closeDialog = () => setIsDialogOpen(false);

  const [createJob] = useMutation(JOB_CREATE_ONE, {
    refetchQueries: [{ query: JOB_MANY }],
  });
  const [createVacancy] = useMutation(VACANCY_CREATE_ONE, {
    refetchQueries: [{ query: VACANCY_MANY }],
  });

  const { formatMessage: f } = useIntl();
  const formattedSalary = currencyFormater.format(job.salary);

  const onCopy = () => {
    const competences = job.competences.map((c: Competence) => ({
      skillId: c.skillId,
      value: c.value,
    }));
    const name = `${job.name} Kopie`;

    createJob({
      variables: { ...job, competences, name },
    });
  };

  const confirmCreateVacancy = (isConfirmed: boolean) => {
    if (isConfirmed) {
      const competences = job.competences.map((c: Competence) => ({
        skillId: c.skillId,
        value: c.value,
      }));

      createVacancy({
        variables: {
          name: job.name,
          description: job.description,
          salary: +job.salary,
          competences: competences,
          unitId: job.unitId ? job.unitId : null,
          jobId: job._id,
        },
      });
    }
    history.push("/vacancies");
  };

  return (
    <tr>
      <td className="px-6 py-4">
        <Confirm
          title="Stellenangebot erstellen"
          description={`Neues Stellengebot auf Basis des Arbeitsplatzes ${job.name} erstellen?`}
          isOpen={isDialogOpen}
          closeDialog={closeDialog}
          onConfirm={confirmCreateVacancy}
        />

        <div className="flex items-center">
          <Link
            to={`/jobs/${job._id}/details`}
            className="text-gray-900 text-sm leading-5 font-medium hover:text-gray-600 transition ease-in-out duration-150"
          >
            {job.name}
            <p className="text-sm text-gray-500 overflow-ellipsis">
              {job.description}
            </p>
          </Link>
        </div>
      </td>
      <td className="px-6 py-4"></td>
      <td className="px-6 py-4">
        <div className="pt-2 px-6 flex items-center flex-no-wrap max-w-xs overflow-x-scroll">
          {job.employees.slice(0, 5).map((employee: Employee) => (
            <Link
              to={`/employees/${employee._id}/details`}
              className="w-12 h-12 bg-cover bg-center rounded-md -ml-2"
            >
              <Avatar
                key={employee._id}
                employee={employee}
                className="h-full w-full overflow-hidden object-cover rounded-full border-2 border-white dark:border-gray-700 shadow"
              />
            </Link>
          ))}
        </div>
      </td>
      <PermissionRequired module="jobs.competency_profiles">
        <td className="px-6 py-4">
          <Link to={`/jobs/${job._id}/profile`}>
            <div className="h-6 w-12">
              <MicroChart data={job.competences} />
            </div>
          </Link>
        </td>
      </PermissionRequired>
      <PermissionRequired module="jobs.salaray">
        <td className="px-6 py-4 whitespace-nowrap">
          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
            {formattedSalary}
          </span>
        </td>
      </PermissionRequired>
      <td className="px-6 py-4">
        <Menu as="div" className="relative inline-block text-left">
          {({ open }) => (
            <>
              <div>
                <Menu.Button className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-black rounded-md bg-opacity-20 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                  {f({ id: "actions.name" })}
                  <ChevronDownIcon
                    className="w-5 h-5 ml-2 -mr-1 text-violet-200 hover:text-violet-100"
                    aria-hidden="true"
                  />
                </Menu.Button>
              </div>
              <Transition
                show={open}
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  static
                  className="z-10 absolute right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                  <div className="px-1 py-1 ">
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to={`/jobs/${job._id}/details`}
                          module="jobs.details"
                          className={`${
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700"
                          } flex justify-between w-full px-4 py-2 text-sm leading-5 text-left`}
                        >
                          {f({ id: "actions.details" })}
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to={`/jobs/${job._id}/profile`}
                          module="jobs.competency_profiles"
                          className={`${
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700"
                          } flex justify-between w-full px-4 py-2 text-sm leading-5 text-left`}
                        >
                          {f({ id: "profile.name" })}
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <span
                          className={`${
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700"
                          } flex justify-between w-full px-4 py-2 text-sm leading-5 text-left cursor-pointer`}
                          onClick={onCopy}
                        >
                          {f({ id: "actions.copy" })}
                        </span>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <span
                          className={`${
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700"
                          } flex justify-between w-full px-4 py-2 text-sm leading-5 text-left cursor-pointer`}
                          onClick={openDialog}
                        >
                          Stellenangebot erstellen
                        </span>
                      )}
                    </Menu.Item>
                  </div>
                  <div className="px-1 py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to={{
                            pathname: `/jobs/${job._id}/match`,
                            state: { competences: job.competences },
                          }}
                          module="jobs.employee_matches"
                          className={`${
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700"
                          } flex justify-between w-full px-4 py-2 text-sm leading-5 text-left`}
                        >
                          {f({ id: "jobs.match" })}
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item disabled={job.vacancyCount === 0}>
                      {({ active, disabled }) =>
                        disabled ? (
                          <span className="flex justify-between w-full px-4 py-2 text-sm leading-5 text-left text-gray-700 cursor-not-allowed opacity-50">
                            {f({ id: "vacancies.name" })}
                          </span>
                        ) : (
                          <Link
                            to={`/jobs/${job._id}/vacancies`}
                            className={`${
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700"
                            } flex justify-between w-full px-4 py-2 text-sm leading-5 text-left`}
                          >
                            {f({ id: "vacancies.name" })}
                          </Link>
                        )
                      }
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu>
      </td>
    </tr>
  );
};

export default Item;
